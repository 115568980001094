import React from 'react'

import {
  RawHtml,
  AnchorWatcher,
  ContentElements,
  AddToCartSuccessToast,
  AddToCartErrorToast,
  AddToSubscriptionToast,
} from '../..'
import { useSnippet } from '../../../utils'
import VisitorTracking from '../VisitorTracking'
import Copyright from './Copyright'

function Footer() {
  const [topSnippet, linksSnippet] = useSnippet(['footer_top', 'footer_links'])
  const footerLinks = linksSnippet?.[0]?.properties?.content?.richText

  return (
    <>
      <ContentElements elements={topSnippet} />
      <section className="footer">
        {footerLinks && (
          <RawHtml element="div" className="container row" html={footerLinks} />
        )}
        <Copyright />
      </section>
      <AddToCartSuccessToast />
      <AddToCartErrorToast />
      <AddToSubscriptionToast />
      <AnchorWatcher />
      <VisitorTracking />
    </>
  )
}

export default Footer
export { default as footerVariants } from './variants.js'
