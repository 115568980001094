import React from 'react'
import PropTypes from 'prop-types'

import { Button, Icon } from '../../index'
import { useTranslation } from '../../../utils/providers'

function ToastHeader({ icon, closeAction, children }) {
  const { t } = useTranslation()

  return (
    <div className="toast-message__header">
      {icon && <Icon symbol={icon} className="toast-message__header-icon" />}
      <div className="toast-message__header-content">{children}</div>
      <Button
        variant="inline"
        className="toast-message__close-button"
        title={t('CLOSE_WINDOW')}
        onClick={closeAction}
      >
        <Icon symbol="cf-close-light" />
      </Button>
    </div>
  )
}

ToastHeader.propTypes = {
  closeAction: PropTypes.func.isRequired,
  icon: PropTypes.string,
  children: PropTypes.any,
}

export default ToastHeader
