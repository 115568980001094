import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/**
 * An HTML `<button>` element mimicking inline text. This is **not** the same
 * as using a `<Button variant="inline">` component! While the `Button`
 * component always renders the `.btn` CSS class, including default button
 * styles like padding, this component does not. Use this component if you
 * need a "clean" inline button.
 *
 * @param {string} [className] - Additional CSS classes, if required.
 * @param {any} [children] - Visible content, like text or icons.
 * @param {any} [rest] - Additional props that are passed as HTML attributes.
 * @returns {JSX.Element}
 * @constructor
 */
export default function InlineButton({ className, children, ...rest }) {
  const buttonClasses = classNames('btn-inline', className)
  return (
    <button type="button" className={buttonClasses} {...rest}>
      {children}
    </button>
  )
}

InlineButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
}
