import React from 'react'
import PropTypes from 'prop-types'

function ToastFooter({ children }) {
  return <div className="toast-message__footer">{children}</div>
}

ToastFooter.propTypes = {
  children: PropTypes.any,
}

export default ToastFooter
