import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ConditionalLink, LoadingSpinner } from '../../index'

/**
 * A generic button component that renders different HTML elements, depending
 * on additional props passed to it. For example, when passing an `href` it
 * renders an `<a>` tag. Passing only an `onClick` function will render a
 * `<button>`. Passing both `href` and `onClick` renders an `<a>` tag with
 * the additional event handler. Falls back to a `<button>` element in case
 * `href` is false-ish.
 *
 * @param {string} [variant="secondary"] - A predefined color/style variant.
 *   A note for the `inline` variant: this component always renders the
 *   default `.btn` CSS class, including padding, text transformations etc..
 *   If you need a `<button>` mimicking *real* inline text, use the
 *   `InlineButton` component instead.
 * @param {string} [size=null] - `sm` for a smaller button, `lg` for a larger
 *   button. Leave unset for a "medium" sized button (default).
 * @param {string} [className=null] - Additional CSS classes, if required.
 * @param {string} [href=null] - A link to some page, if required. Has no
 *   effect when either `disabled` or `isLoading` is active.
 * @param {string} [type="button"] - For `submit` or `reset` buttons or
 *   similar. Defaults to `button`.
 * @param {boolean} [disabled=false] - Renders the button as disabled, setting
 *   the `disabled` HTML attribute as well as the `.disabled` CSS class.
 * @param {boolean [isLoading=false] - Renders a loading spinner when set to
 *   `true`, hiding the `children` inside an invisible `<div>` to keep the
 *   width from changing. **Implicitly** sets the `disabled` HTML attribute
 *   but does **not** add the `.disabled` CSS class as with the `disabled`
 *   prop! If you want to keep the text visible, don't use this prop and
 *   manually render a {@link LoadingSpinner} as part of the `children`.
 * @param {any} [children] - Visible content, like text or icons.
 * @param {any} [rest] - Additional props that are passed as HTML attributes.
 * @returns {JSX.Element}
 * @constructor
 */
function Button({
  variant = 'secondary',
  size = null,
  className = null,
  type = 'button',
  href = null,
  isLoading = false,
  disabled = false,
  children,
  ...rest
}) {
  const classes = classNames(className, 'btn', {
    [`btn-${variant}`]: variant,
    [`btn-${size}`]: size,
    'btn-loading': isLoading,
    disabled: disabled,
  })

  const content = isLoading ? (
    <>
      {children && <div className="invisible">{children}</div>}
      <LoadingSpinner />
    </>
  ) : (
    children
  )

  // Separate check to properly render disabled link-buttons
  if (isLoading || disabled) {
    return (
      <button
        className={classes}
        type={type}
        disabled={isLoading || disabled}
        {...rest}
      >
        {content}
      </button>
    )
  }

  return (
    <ConditionalLink
      href={href}
      fallbackElement="button"
      className={classes}
      type={type}
      {...rest}
    >
      {content}
    </ConditionalLink>
  )
}

Button.propTypes = {
  /**
   * Common variants include, but are not limited to:
   * 'primary', 'outline-primary', 'secondary', 'outline-secondary', 'inline'
   */
  variant: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  className: PropTypes.string,
  href: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.any,
}

export default Button
export { default as buttonVariants } from './variants'
