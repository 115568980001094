import { useEffect, useState } from 'react'

/**
 * Register an event name, alongside a callback, and get a visibility state as
 * well as a close function to be used in conjunction with the {@link Toast}
 * component.
 *
 * The given callback is passed the window event object and its return value
 * is evaluated as a precondition in order to set the visibility state to
 * `true`. The visibility is `false` by default.
 *
 * @param {string} eventName - The window event to listen to
 * @param {function} validateEvent - A callback to evaluate when receiving the
 *   given window event. Must return `true` for the the visibility state to be
 *   set to `true`
 * @returns {{isVisible: boolean, closeAction: function}}
 */
function useToastEvent(eventName, validateEvent) {
  if (typeof eventName !== 'string') {
    throw new TypeError('Invalid event name')
  }
  if (typeof validateEvent !== 'function') {
    throw new TypeError('Invalid event callback')
  }

  const [isVisible, setVisible] = useState(false)

  const closeAction = () => {
    setVisible(false)
  }

  useEffect(() => {
    let isMounted = true

    const showAction = (evt) => {
      if (validateEvent(evt) && isMounted) {
        setVisible(true)
      }
    }

    window.addEventListener(eventName, showAction)
    return () => {
      isMounted = false
      window.removeEventListener(eventName, showAction)
    }
  }, [])

  return {
    isVisible,
    closeAction,
  }
}

export default useToastEvent
