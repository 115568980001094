import React, { useState } from 'react'

import Toast, { ToastHeader, useToastEvent } from '../index'

function AddToCartErrorToast() {
  const [message, setMessage] = useState(null)

  const { isVisible, closeAction } = useToastEvent(
    'addtocart:response',
    ({ detail: { status, message } }) => {
      if (status !== 'ok') {
        setMessage(message)
        return true
      }
      return false
    }
  )

  return (
    <Toast
      isVisible={isVisible}
      closeAction={closeAction}
      data-testid="add-to-cart-error"
    >
      <ToastHeader icon="cf-warning" closeAction={closeAction}>
        <strong>{message}</strong>
      </ToastHeader>
    </Toast>
  )
}

export default AddToCartErrorToast
