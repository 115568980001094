import React from 'react'
import classNames from 'classnames'

import { Button, Text, Image, RawHtml } from '../..'
import { truncateString } from '../../../utils'

export default function InfoCard({
  heading = '',
  description = {},
  bgColor = '',
  actionBtn = {},
  image = '',
}) {
  const descriptionText =
    description && description.text ? truncateString(description.text, 160) : ''

  const containerClass = classNames('info-card shadow-sm px-3 mb-4', bgColor)
  const descriptionClass = classNames(
    'd-block list-item-checkmark',
    description?.align
  )

  return (
    <div className={containerClass}>
      {heading && (
        <Text
          element="span"
          text={heading}
          className="text-uppercase d-block text-primary mb-3"
          size="m"
          weight="semi-bold"
        />
      )}
      {descriptionText && (
        <RawHtml
          className={descriptionClass}
          html={descriptionText}
          forceStrictMode={true}
        />
      )}
      {actionBtn?.text && actionBtn?.target && (
        <div className="pt-4">
          <Button
            variant="primary"
            href={actionBtn.target}
            className="px-4 py-2"
          >
            {actionBtn.text}
          </Button>
        </div>
      )}
      {image && <Image srcDesktop={image} />}
    </div>
  )
}
