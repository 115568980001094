import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import classNames from 'classnames'

import { Button, Link, RawHtml, AlertBox, AlertBoxTypes } from '../../index'
import { ErrorMessage, MultiCheckbox } from '../Form'
import { CaptchaError, FormStatus, useForm } from '../../../utils'
import { useBackendApi, useTranslation } from '../../../utils/providers'

export default function NewsletterForm({
  identifier = 'default',
  interestList = [],
  placeholder = null,
  privacyPolicyUrl = '/',
  borderStyle = 'angled',
  order: orderProps = {
    interests: 1,
    input: 2,
    legalNotice: 3,
  },
  interestButtonSize = 'small',
  submitButtonPlacement = 'integratedGap',
  onSubmitStatusChange,
  isCrossengage,
  privacyPolicyText,
  hidePrivacyPolicy,
}) {
  const [showPrivacyPolicyText, setShowPrivacyPolicyText] = useState(
    !hidePrivacyPolicy
  )
  const router = useRouter()
  const { t } = useTranslation()
  const backendApi = useBackendApi()

  const createFormData = () => {
    const formData = {
      email: {
        name: 'email',
        schema: Yup.string()
          .trim()
          .email(t('FORM_ERROR_WRONG_EMAIL'))
          .required(t('FORM_ERROR_REQ_EMAIL')),
        value: '',
        error: null,
      },
      interests: {
        name: 'interests',
        schema: Yup.array()
          .of(Yup.string().oneOf([interestList.map((i) => i.value)]))
          .min(1, t('NEWSLETTER_INTEREST_CHECK_ERROR'))
          .required(),
        value:
          interestList?.filter((i) => i.selected).map((i) => i.value) || [],
        error: null,
      },
    }
    return formData
  }

  const openPrivacyPolicyText = () => setShowPrivacyPolicyText(true)

  const onSubmit = async ({ body, token }) => {
    const finalBody = {
      'g-recaptcha-response': token,
      newsletter_email: body.email,
      newsletter_interest: body.interests,
    }
    if (isCrossengage) {
      finalBody['crossengage'] = '1'
    }

    return await backendApi.submitNewsletterForm(finalBody)
  }

  const { handleSubmit, handleChange, formData, formStatus, error, resetForm } =
    useForm({
      createFormData,
      onSubmit,
      validateOnChange: true,
      captchaActionName: 'NEWSLETTER_SUBSCRIBE',
    })

  // Don't show status messages in this component when status listener
  // callbacks are defined but pass it along. Captcha errors are an exception.
  const showStatus = typeof onSubmitStatusChange !== 'function'
  useEffect(() => {
    if (
      !(
        formStatus === FormStatus.ERROR &&
        (error instanceof CaptchaError || !error)
      ) &&
      !showStatus
    ) {
      onSubmitStatusChange(formStatus)
    }
    let timeout
    if (formStatus === FormStatus.SUCCESS) {
      timeout = setTimeout(() => {
        resetForm()
      }, 5000)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [formStatus, error])

  useEffect(() => {
    const hidePrivacyPolicyText = () => setShowPrivacyPolicyText(false)

    router.events.on('routeChangeComplete', hidePrivacyPolicyText)

    return () => {
      router.events.off('routeChangeComplete', hidePrivacyPolicyText)
    }
  }, [])

  if (!interestList || interestList.length === 0) {
    return null
  }

  const emailInputId = `${identifier}_2newsletter_email_input`
  const checkboxIdPrefix = `${identifier}_2newsletter_interest`

  const order = {
    interests: 1,
    input: 2,
    legalNotice: 3,
  }
  // Normalize order to [1..3] by sorting with the entry values and using the sorted index + 1 as output
  Object.entries(orderProps)
    .sort((a, b) => a[1] - b[1])
    .map((o, i) => {
      order[o[0]] = i + 1
    })

  const interestWrapperClasses = classNames('mb-2', `order-${order.interests}`)
  const interestButtonClasses = classNames('btn-checkbox mt-lg-0 px-4', {
    'py-1': interestButtonSize === 'small',
    'py-2': interestButtonSize === 'large',
    'btn-checkbox--rounded-corners': borderStyle === 'round',
  })
  const inputWrapperClasses = classNames('mb-2', `order-${order.input}`)
  const inputGroupClasses = classNames('input-group', {
    'input-group--rounded-corners':
      borderStyle === 'round' &&
      ['integrated', 'integratedGap'].includes(submitButtonPlacement),
  })
  const inputClasses = classNames('form-control mb-sm-0 bg-white', {
    'input--rounded-corners':
      borderStyle === 'round' && submitButtonPlacement === 'separate',
    'mr-sm-2': submitButtonPlacement === 'integratedGap',
  })
  const privacyClasses = classNames(
    'form-group mb-2 d-inline-block',
    `order-${order.legalNotice}`
  )
  const submitClasses = classNames({
    'input-group-append': ['integrated', 'integratedGap'].includes(
      submitButtonPlacement
    ),
    'btn--rounded-corners':
      borderStyle === 'round' && submitButtonPlacement === 'separate',
  })

  const btnSubmit = (
    <Button
      variant="primary"
      type="submit"
      className={submitClasses}
      isLoading={formStatus === FormStatus.PENDING}
    >
      {t('NEWSLETTER_SUBMIT')}
    </Button>
  )
  const inputEmail = (
    <input
      type="email"
      name="email"
      className={inputClasses}
      id={emailInputId}
      placeholder={placeholder}
      onChange={handleChange}
      defaultValue={formData.email.value}
      spellCheck={false}
      onFocus={openPrivacyPolicyText}
    />
  )

  return (
    <form className="newsletter__form" method="post" onSubmit={handleSubmit}>
      {interestList?.length > 1 && (
        <MultiCheckbox
          data={formData.interests}
          className={interestWrapperClasses}
          itemWrapper="fragment"
          itemWrapperClassName={null}
          inputClassName="d-none"
          labelClassName={interestButtonClasses}
          onChange={handleChange}
          options={interestList}
          htmlId={checkboxIdPrefix}
        />
      )}

      <div className={inputWrapperClasses}>
        {['integrated', 'integratedGap'].includes(submitButtonPlacement) ? (
          <div className={inputGroupClasses}>
            {inputEmail}
            {btnSubmit}
          </div>
        ) : (
          inputEmail
        )}
        {formData.email?.error && (
          <ErrorMessage message={formData.email?.error} />
        )}
      </div>

      {showPrivacyPolicyText && (
        <div className={privacyClasses}>
          {privacyPolicyText ? (
            <RawHtml
              element="div"
              html={privacyPolicyText}
              forceStrictMode={true}
            />
          ) : (
            t('NEWSLETTER_PRIVACY_CHECKBOX')({
              Link,
              props: {
                href: privacyPolicyUrl,
                target: '_blank',
                rel: 'noreferrer',
                className: 'text-underline',
              },
            })
          )}
        </div>
      )}

      {submitButtonPlacement === 'separate' && (
        <div className="mt-2 order-3">{btnSubmit}</div>
      )}

      {error instanceof CaptchaError && (
        <p className="recaptcha-consent-error">
          {t('NEWSLETTER_PRIVACY_SETTINGS_ERROR')({
            Link,
            props: {
              href: privacyPolicyUrl,
              target: '_blank',
              rel: 'noreferrer',
              className: 'text-underline',
            },
          })}
        </p>
      )}
      {showStatus && formStatus === FormStatus.SUCCESS && (
        <AlertBox type={AlertBoxTypes.SUCCESS} className="w-100">
          {t('NEWSLETTER_SUBSCRIPTION_SUCCESS')}
        </AlertBox>
      )}
      {showStatus &&
        formStatus === FormStatus.ERROR &&
        !(error instanceof CaptchaError) && (
          <AlertBox type={AlertBoxTypes.ERROR} className="w-100">
            {t('NEWSLETTER_SUBSCRIPTION_ERROR')}
          </AlertBox>
        )}
    </form>
  )
}

NewsletterForm.propTypes = {
  /**
   * Add the 'crossengage' flag to the subscription request
   */
  isCrossengage: PropTypes.bool,
  /**
   * An identifier, used to give form elements unique IDs
   */
  identifier: PropTypes.string,
  /**
   * Requires at least one entry, otherwise the component returns 'null'
   */
  interestList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      selected: PropTypes.bool,
    })
  ).isRequired,
  /**
   * Optional callback that is called when the submit-state changes due to API requests
   */
  onSubmitStatusChange: PropTypes.func,
  /**
   * Placeholder for the e-mail input field
   */
  placeholder: PropTypes.string,
  /**
   * URL to the privacy policy page
   */
  privacyPolicyUrl: PropTypes.string.isRequired,
  /**
   * Optional, defaults to 'angled'
   */
  borderStyle: PropTypes.oneOf(['angled', 'round']),
  /**
   * Optional, defaults to 'small'
   */
  interestButtonSize: PropTypes.oneOf(['large', 'small']),
  /**
   * Render the submit button as an input group or separated from the input.
   * Optional, defaults to 'integratedGap'
   */
  submitButtonPlacement: PropTypes.oneOf([
    'separate',
    'integrated',
    'integratedGap',
  ]),
  /**
   * Optional, change the default order of input groups
   */
  order: PropTypes.shape({
    /**
     * Defaults to '1'
     */
    interests: PropTypes.number,
    /**
     * Defaults to '2'
     */
    input: PropTypes.number,
    /**
     * Defaults to '3'
     */
    legalNotice: PropTypes.number,
  }),
  /**
   * Optional, replace default text with custom rich text
   */
  privacyPolicyText: PropTypes.string,
  /**
   * Optional, hide the privacy policy text initially and show it only when the user interacts with the email field
   */
  hidePrivacyPolicy: PropTypes.bool,
}

NewsletterForm.defaultProps = {
  identifier: null,
  interestList: [],
  placeholder: null,
  privacyPolicyUrl: '/',
  borderStyle: 'angled',
  order: {
    interests: 1,
    input: 2,
    legalNotice: 3,
  },
  interestButtonSize: 'small',
  submitButtonPlacement: 'integrated',
  onSubmitStatusChange: null,
  isCrossengage: false,
  privacyPolicyText: '',
  hidePrivacyPolicy: false,
}
