import React, { useEffect, useState } from 'react'
import { Router } from 'next/router'

import { CenterPopup } from '../../index'
import {
  dispatchHideOverlayEvent,
  dispatchShowOverlayEvent,
  getParameterByName,
} from '../../../utils'

import { LoginPopupTypes } from './LoginPopupTypes'
import {
  LoginForm,
  RegisterForm,
  ForgotPasswordForm,
  NewPasswordForm,
  ConfirmPasswordForm,
} from './Forms'

function dispatchLoginPopupShowEvent(params = {}) {
  const evt = new CustomEvent('loginpopup:show', {
    detail: params,
  })
  window.dispatchEvent(evt)
}

function dispatchLoginPopupHideEvent() {
  const evt = new Event('loginpopup:hide')
  window.dispatchEvent(evt)
}

export {
  dispatchLoginPopupHideEvent,
  dispatchLoginPopupShowEvent,
  LoginPopupTypes,
}

export default function LoginPopup() {
  const [mode, setMode] = useState('login')
  const [isVisible, setVisible] = useState(false)
  const [headline, setHeadline] = useState(null)
  const [callbacks, setCallbacks] = useState({})

  const showPopup = (evt) => {
    const { mode, headline, onAbort } = evt?.detail || {}
    setMode(mode || 'login')
    setHeadline(headline)
    setCallbacks({ onAbort })
    setVisible(true)
    dispatchShowOverlayEvent()
  }

  const hidePopup = () => {
    setVisible(false)
    dispatchHideOverlayEvent()
  }

  const handleAbort = () => {
    hidePopup()
    if (typeof callbacks?.onAbort === 'function') {
      callbacks.onAbort()
    }
  }

  // Check if login popup needs to be opened when a page loads
  const checkPopupParam = () => {
    let param = getParameterByName({ selector: 'goto' })
    // Hack: Remove trailing slash from the parameter value, which Magento
    // likes to add on redirects for whatever reason.
    param = param?.replace(/\/$/, '')

    if (Object?.values(LoginPopupTypes)?.indexOf(param) > -1) {
      showPopup({ detail: { mode: param } })
    }
  }

  useEffect(() => {
    window.addEventListener('loginpopup:show', showPopup)
    window.addEventListener('loginpopup:hide', hidePopup)
    Router.events.on('routeChangeComplete', checkPopupParam)
    checkPopupParam()

    return () => {
      window.removeEventListener('loginpopup:show', showPopup)
      window.removeEventListener('loginpopup:hide', hidePopup)
      Router.events.off('routeChangeComplete', checkPopupParam)
    }
  }, [])

  if (!isVisible) {
    return null
  }

  const components = {
    [LoginPopupTypes.LOGIN]: LoginForm,
    [LoginPopupTypes.REGISTER]: RegisterForm,
    [LoginPopupTypes.FORGOT_PASSWORD]: ForgotPasswordForm,
    [LoginPopupTypes.NEW_PASSWORD]: NewPasswordForm,
    [LoginPopupTypes.CONFIRM_REGISTRATION]: ConfirmPasswordForm,
  }
  const Form = components[mode]

  return (
    <CenterPopup isVisible={isVisible} closeAction={handleAbort}>
      {Form && (
        <Form
          key={mode}
          headline={headline}
          setMode={setMode}
          closeAction={hidePopup}
        />
      )}
    </CenterPopup>
  )
}
