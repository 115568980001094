import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import AlertBoxTypes from './AlertBoxTypes'
import { default as alertBoxVariants } from './variants'
import { Icon } from '../../'

function AlertBox({
  className,
  children,
  type = AlertBoxTypes.ERROR,
  style = 'filled',
}) {
  if (!children) {
    return null
  }

  let iconToRender
  switch (type) {
    case AlertBoxTypes.ERROR:
      iconToRender = 'cf-close-circle-o'
      break
    case AlertBoxTypes.SUCCESS:
      iconToRender = 'cf-check-circle-o'
      break
    case AlertBoxTypes.WARNING:
      iconToRender = 'cf-warning'
      break
    case AlertBoxTypes.INFO:
      iconToRender = 'cf-info-circle-o'
      break
    default:
      iconToRender = null
      break
  }

  const content = children instanceof Error ? children.toString() : children
  const containerClass = classNames(
    `alert d-flex align-items-center alert-${style}`,
    type,
    className,
    {
      'p-1': style === 'plain',
    }
  )

  return (
    <div className={containerClass}>
      {iconToRender && (
        <Icon symbol={iconToRender} className="mr-2 font-size-m" />
      )}
      <div className="flex-grow text-left">{content}</div>
    </div>
  )
}

AlertBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  type: PropTypes.oneOf(Object.values(AlertBoxTypes)),
  style: PropTypes.oneOf(['filled', 'plain']),
}

export { AlertBox, AlertBoxTypes, alertBoxVariants }
