import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { Image } from '../../index'
import Item from './Item'

export default function CopyCouponCards({
  isFullWidth,
  headlineImage,
  headline,
  bgColor,
  headlineFont,
  headlineColor,
  hasShadow,
  hasBorder,
  hasBorderRadius,
  cards,
}) {
  const wrapperClasses = classNames('copy-coupon-cards mb-3 py-3', {
    'bg-gray-4': bgColor === 'gray-4',
    'bg-bg-light': bgColor === 'bg-light',
    'bg-white': bgColor === 'white',
    container: !isFullWidth,
  })
  const cardRowClasses = classNames('row copy-coupon-cards__row-container', {
    'copy-coupon-cards--one-card': cards.length === 1,
    'copy-coupon-cards--two-cards': cards.length === 2,
    'copy-coupon-cards--three-cards': cards.length >= 3,
  })
  const cardColumnClasses = classNames({
    'col-12': cards.length === 1,
    'col-12 col-lg-6': cards.length === 2,
    'col-12 col-lg-4': cards.length >= 3,
  })
  const cardClasses = classNames(
    'copy-coupon-cards__column-container p-4 position-relative overflow-hidden bg-white',
    {
      shadow: hasShadow,
      'border border-gray-3': hasBorder,
      'border-radius-sm': hasBorderRadius,
    }
  )
  const firstColClasses = classNames(
    'copy-coupon-cards__column-1 text-center mx-auto col-12 col-sm-6',
    {
      'col-md-4 col-lg-6': cards.length === 1 || cards.length === 2,
      'col-md-4 col-lg-12 col-xl-12': cards.length >= 3,
    }
  )
  const secondColClasses = classNames(
    'copy-coupon-cards__column-2 text-center col-12 col-sm-6 ',
    {
      'col-md-8 col-lg-6': cards.length === 1,
      'col-md-8 col-lg-12 col-xl-6': cards.length === 2,
      'col-md-8 col-lg-12 col-xl-12': cards.length >= 3,
    }
  )

  return (
    <section className={wrapperClasses}>
      {headlineImage || headline ? (
        <div className="copy-coupon-cards__head mb-3">
          {headlineImage && (
            <Image
              srcDesktop={headlineImage}
              alt="headline-image"
              className="copy-coupon-cards__head__image"
            />
          )}
          {headline && (
            <span
              className={`copy-coupon-cards__head__text text-${headlineColor} ${headlineFont}`}
            >
              {headline}
            </span>
          )}
        </div>
      ) : null}
      <div className={isFullWidth ? 'container' : null}>
        <div className={cardRowClasses}>
          {cards.slice(0, 3).map((card, cardItem) => (
            <Item
              card={card}
              key={cardItem}
              cardColumnClasses={cardColumnClasses}
              cardClasses={cardClasses}
              firstColClasses={firstColClasses}
              secondColClasses={secondColClasses}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

CopyCouponCards.propTypes = {
  isFullWidth: PropTypes.bool,
  headlineImage: PropTypes.string,
  headline: PropTypes.string,
  headlineFont: PropTypes.oneOf([
    'font-family-OpenSans',
    'font-family-DancingScript',
    'font-family-Bitter',
    'font-family-Mansalva',
    'font-family-Roboto',
    'font-family-RobotoSlab',
    'font-family-SourceSansPro',
    'font-family-Gotcha',
    'font-family-DidotLTPro',
    'font-family-HelveticaNeue',
  ]),
  headlineColor: PropTypes.oneOf(['gray-1', 'primary']),
  bgColor: PropTypes.oneOf(['gray-4', 'bg-light', 'white']),
  hasShadow: PropTypes.bool,
  cards: PropTypes.arrayOf(PropTypes.object),
}

CopyCouponCards.defaultProps = {
  isFullWidth: false,
  headlineImage: '',
  headline: '',
  headlineFont: 'font-family-OpenSans',
  headlineColor: 'gray-1',
  bgColor: 'white',
  hasShadow: true,
  cards: [],
}

export { default as CopyCouponCardsVariants } from './variants.js'
