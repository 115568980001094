import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { shouldOpenInNewTab, toIndex } from '../../../utils'
import { useConfiguration } from '../../../utils/providers'
import { Button, ConditionalLink, Image } from '../../index'

const Item = ({
  card,
  hasBorder,
  hasShadow,
  hasRoundedCorners,
  headlineColor,
  headlineLevel,
  openInNewTab,
  imageOrder,
  iconOrder,
  headlineOrder,
  mainTextOrder,
  cardIndex,
}) => {
  const {
    bgColor,
    link,
    desktopImage,
    mobileImage,
    icon,
    headline,
    mainText,
    btnText,
  } = card
  const cardClasses = classNames(
    `universal-cards__item w-100 d-flex flex-column bg-${bgColor}`,
    {
      shadow: hasShadow,
      'border border-gray-3': hasBorder,
      'border-radius-sm overflow-hidden': hasRoundedCorners,
    }
  )
  const Element = headlineLevel
  const { getImageLink } = useConfiguration()
  const iconFullLink = getImageLink({ source: icon })

  const imageIndex = (desktopImage || mobileImage) && toIndex(imageOrder, 0)
  const iconIndex = icon && toIndex(iconOrder, 1)
  const headlineIndex = headline && toIndex(headlineOrder, 2)
  const mainTextIndex = mainText && toIndex(mainTextOrder, 3)

  const componentsArr = []

  if (desktopImage || mobileImage) {
    const presentIndexes = [
      imageIndex,
      iconIndex,
      headlineIndex,
      mainTextIndex,
    ].filter(Boolean)
    const imageClasses = classNames(
      'universal-cards__item-image',
      'mx-auto',
      Math.min(...presentIndexes) === imageIndex && 'mt-0',
      Math.max(...presentIndexes) === imageIndex && !(btnText && link) && 'mb-0'
    )

    componentsArr[imageIndex] = (
      <Image
        srcDesktop={desktopImage}
        srcMobile={mobileImage}
        alt="card-image"
        className={imageClasses}
      />
    )
  }

  if (icon) {
    componentsArr[iconIndex] = (
      <img
        alt="icon"
        src={iconFullLink}
        className="universal-cards__item-icon mx-auto"
      />
    )
  }

  if (headline) {
    componentsArr[headlineIndex] = (
      <Element
        className={`px-4 d-block text-truncate text-center text-${headlineColor}`}
      >
        {headline}
      </Element>
    )
  }

  if (mainText) {
    const mainTextClasses = classNames(
      'px-4',
      'text-dark',
      'd-block',
      'text-center',
      'universal-cards__item-text'
    )

    componentsArr[mainTextIndex] = (
      <span className={mainTextClasses}>{mainText}</span>
    )
  }

  if (btnText && link) {
    componentsArr.push(
      <div className="px-4 mb-4 w-100 mt-auto">
        <Button
          variant="primary"
          className="w-100"
          href={link}
          {...shouldOpenInNewTab(openInNewTab)}
        >
          {btnText}
        </Button>
      </div>
    )
  }

  const componentsArrFiltered = componentsArr.filter(Boolean)

  return (
    <ConditionalLink
      className={cardClasses}
      href={link}
      btnText={btnText}
      {...shouldOpenInNewTab(openInNewTab)}
    >
      {componentsArrFiltered.map((item, i) => (
        <Fragment key={`${cardIndex}_universal_card_section_${i}`}>
          {item}
        </Fragment>
      ))}
    </ConditionalLink>
  )
}

Item.propTypes = {
  bgColor: PropTypes.string,
  link: PropTypes.string,
  desktopImage: PropTypes.string,
  mobileImage: PropTypes.string,
  icon: PropTypes.string,
  headline: PropTypes.string,
  mainText: PropTypes.string,
  btnText: PropTypes.string,
}

Item.defaultProps = {
  bgColor: 'white',
  link: '',
  desktopImage: '',
  mobileImage: '',
  icon: '',
  headline: '',
  mainText: '',
  btnText: '',
}

export default Item
