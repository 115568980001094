import React, { useEffect, useState } from 'react'

import { Button } from '../../../index'
import { useTranslation } from '../../../../utils/providers'

import Toast, { ToastHeader, ToastFooter, useToastEvent } from '../index'

function AddToCartSuccessToast() {
  // eslint-disable-next-line no-unused-vars
  const [productData, setProductData] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    let isMounted = true

    const callback = ({ detail: { productData } }) => {
      if (isMounted) {
        setProductData(productData)
      }
    }

    window.addEventListener('addtocart:request', callback)

    return () => {
      window.removeEventListener('addtocart:request', callback)
      isMounted = false
    }
  }, [])

  const { isVisible, closeAction } = useToastEvent(
    'addtocart:response',
    ({ detail: { status } }) => status === 'ok'
  )

  return (
    <Toast
      isVisible={isVisible}
      closeAction={closeAction}
      data-testid="add-to-cart-success"
    >
      <ToastHeader icon="cf-added-cart" closeAction={closeAction}>
        <strong>{t('ADDED_TO_CART')}</strong>
      </ToastHeader>
      <ToastFooter>
        <Button
          variant="outline-primary"
          className="w-100"
          href="/checkout/cart"
          onClick={closeAction}
        >
          {t('CART_VIEW_CART')}
        </Button>
      </ToastFooter>
    </Toast>
  )
}

export default AddToCartSuccessToast
