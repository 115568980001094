import * as Yup from 'yup'

import { StatusData } from './status'

const GetAddressCorrectionResponseData = Yup.object()
  .shape({
    shipping: Yup.object()
      .shape({
        name: Yup.string().required(),
        street: Yup.string().required(),
      })
      .required(),
    billing: Yup.object()
      .shape({
        name: Yup.string().required(),
        street: Yup.string().required(),
      })
      .required(),
    paymentMethod: Yup.object()
      .shape({
        name: Yup.string().required(),
        editUrl: Yup.string().required(),
      })
      .required(),
    validationError: Yup.object()
      .shape({
        billing: Yup.array().of(Yup.string()).required(),
        shipping: Yup.array().of(Yup.string()).required(),
      })
      .required(),
  })
  .concat(StatusData)

const SetAddressCorrectionRequestData = Yup.object()
  .shape({
    shipping: Yup.object()
      .shape({
        name: Yup.string().required(),
        street: Yup.string().required(),
      })
      .required(),
    billing: Yup.object()
      .shape({
        name: Yup.string().required(),
        street: Yup.string().required(),
      })
      .required(),
  })
  .concat(StatusData)

const SetAddressCorrectionResponseData = Yup.object()
  .shape({
    billing: Yup.array().of(Yup.string()).required(),
    shipping: Yup.array().of(Yup.string()).required(),
  })
  .concat(StatusData)

export {
  GetAddressCorrectionResponseData,
  SetAddressCorrectionRequestData,
  SetAddressCorrectionResponseData,
}
