import React from 'react'
import PropTypes from 'prop-types'

function ToastBody({ children }) {
  return <div className="toast-message__body">{children}</div>
}

ToastBody.propTypes = {
  children: PropTypes.any,
}

export default ToastBody
