import React, { useEffect, useRef, useState } from 'react'
import { PropTypes } from 'prop-types'

import { Icon, Text } from '../..'
import { copyTextToClipboard, throttle, useTranslation } from '../../../utils'
import Button from '../Button'
import classNames from 'classnames'

const CopyCoupon = ({
  couponCode,
  couponCodeLabel,
  copyText,
  copiedText,
  className,
  labelAlignment,
  labelFontSize,
  labelWeight,
}) => {
  const { t } = useTranslation()
  const [isCopied, setIsCopied] = useState(false)
  const [showButtonText, setShowButtonText] = useState(true)
  const ref = useRef(null)

  const handleCopyClick = () => {
    copyTextToClipboard(couponCode)
      .then(() => {
        setIsCopied(true)
        setTimeout(() => {
          setIsCopied(false)
        }, 1500)
      })
      .catch(console.error)
  }

  const couponCodeInputContainerClassNames = classNames('d-flex copy-coupon', {
    'copy-coupon--copied': isCopied,
  })
  const copyButtonClassNames = classNames(
    `copy-coupon__button--${isCopied ? 'disabled' : 'active'}`,
    !showButtonText && 'copy-coupon__button--no-text',
    'copy-coupon__button',
    'border-0'
  )
  const containerClasses = classNames('text-center mb-2', className)
  const buttonText = isCopied
    ? copiedText || t('COPY_COUPON_COPIED')
    : copyText || t('COPY_COUPON_COPY')

  useEffect(() => {
    const handleResize = throttle(() => {
      if (ref.current?.offsetWidth > 204) {
        setShowButtonText(true)
      } else {
        setShowButtonText(false)
      }
    }, 100)

    handleResize()

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [ref.current?.offsetWidth])

  if (!couponCodeLabel && !couponCode) {
    return (
      <div
        className="w-100 copy-coupon__instead mb-2"
        data-testid="instead-coupon"
      />
    )
  }

  return (
    <div className={containerClasses}>
      {couponCodeLabel ? (
        <Text
          color="primary"
          size={labelFontSize}
          weight={labelWeight}
          align={labelAlignment}
          text={couponCodeLabel}
          className="mb-2 d-block white-space-nowrap text-truncate"
        />
      ) : (
        <div className="copy-coupon__percentage w-100 mb-2" />
      )}

      <div
        className={couponCodeInputContainerClassNames}
        onClick={(e) => e.preventDefault()}
        ref={ref}
      >
        <label className="d-block m-0 flex-grow">
          <span className="sr-only">{t('COPY_COUPON_LABEL')}</span>
          <input
            type="text"
            value={couponCode}
            readOnly
            className="p-2 border-0 copy-coupon__input w-100 h-100"
            data-testid="code-input"
            spellCheck={false}
          />
        </label>

        <Button
          variant={isCopied ? 'secondary' : 'primary'}
          className={copyButtonClassNames}
          onClick={handleCopyClick}
          disabled={isCopied}
          {...(!showButtonText
            ? {
                title: buttonText,
                'aria-label': buttonText,
              }
            : {})}
        >
          {showButtonText && buttonText}
          <Icon symbol={isCopied ? 'cf-check-light' : 'cf-copy'} />
        </Button>
      </div>
    </div>
  )
}

CopyCoupon.propTypes = {
  couponCode: PropTypes.string,
  couponCodeLabel: PropTypes.string,
  copiedText: PropTypes.string,
  copyText: PropTypes.string,
  className: PropTypes.string,
  labelWeight: PropTypes.string,
  labelAlignment: PropTypes.oneOf(['left', 'center', 'right']),
  labelFontSize: PropTypes.oneOf([
    'xxs',
    'xs',
    'sm',
    'm',
    'l',
    'xl',
    'xxl',
    '3xl',
    'base',
  ]),
}

CopyCoupon.defaultProps = {
  couponCode: '',
  couponCodeLabel: '',
  copiedText: '',
  copyText: '',
  className: null,
  labelWeight: null,
  labelAlignment: 'center',
  labelFontSize: 'xs',
}

export default CopyCoupon
